*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  text-decoration-skip-ink: none;
  underline: none;
}

img{
  max-width: 100%;
  max-height: 100%;
}

body{
  background-color: var(--bg-color);
  font-family: var(--font-primary);
  font-weight: 400;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@keyframes Ab {
  0% {
      transform: scale(.9) rotate3d(3,0,0,50deg);
      opacity: 0
  }

  to {
      transform: scale(1) rotateX(0)
  }
}

@mixin desktop{
  @media(min-width: 992px){
    @content;
  }
}

@mixin mobile{
  @media(max-width: 992px){
    @content;
  }
}

@mixin preload-image{
  &:not([src]) {
      opacity: 0;
  }
  &.swiper-lazy {
      opacity: 0;
      transition: ease-in-out 0.2s;
  }
  &.swiper-lazy-loaded {
      opacity: 1;
  }
}

@mixin container{
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
}

@mixin icons{
  li[class*="icon-"]{
    &.icon-furadeira a::before{
      background-image: var(--header-icon-furadeira);
    }
    &.icon-chave a::before{
      background-image: var(--header-icon-chaves);
    }
    &.icon-plug a::before{
      background-image: var(--header-icon-plug);
    }
    &.icon-bica a::before{
      background-image: var(--header-icon-bica);
    }
    &.icon-cnpj a::before{
      background-image: var(--header-icon-cnpj);
    }
    
    >a{
      &::before{
        content: "";
        display: block;
        height: 20px;
        width: 24px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain
      }
      
    }
  }
}

.container{
  @include container;
}

.overlay_isopened{
	overflow: hidden;
}

.overlay_loading {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,.7);
    z-index: 9999;
}

.overlay_loading .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay_loading .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.overlay_loading .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}