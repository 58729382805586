@font-face{
	font-family: 'AnebaNeue-Bold';
	src: url("https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.ttf") format('truetype');
	font-weight: bold;
    font-display: swap;
}

@font-face{
	font-family: 'AnebaNeue-Bold-Italic';
	src: url("https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.ttf") format('truetype');
	font-weight: bold;
	font-style: italic;
    font-display: swap;
}

@font-face{
	font-family: 'AnebaNeue-Medium';
	src: url("https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.ttf") format('truetype');
	font-weight: normal;
    font-display: swap;
}

@font-face{
	font-family: 'AnebaNeue-Medium-Italic';
	src: url("https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.ttf") format('truetype');
	font-weight: normal;
	font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Aneba Neue SemiBold Italic';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBolditalic.eot');
    src: local('Aneba Neue SemiBold Italic'), local('AnebaNeue-SemiBolditalic'),
        url('AnebaNeue-SemiBolditalic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-SemiBolditalic.woff2') format('woff2'),
        url('AnebaNeue-SemiBolditalic.woff') format('woff'),
        url('AnebaNeue-SemiBolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Medium Italic';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.eot');
    src: local('Aneba Neue Medium Italic'), local('AnebaNeue-Mediumitalic'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Bold Italic';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.eot');
    src: local('Aneba Neue Bold Italic'), local('AnebaNeue-Bolditalic'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Bold';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.eot');
    src: local('Aneba Neue Bold'), local('AnebaNeue-Bold'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Italic';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Italic.eot');
    src: local('Aneba Neue Italic'), local('AnebaNeue-Italic'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Italic.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Italic.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Italic.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Medium';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.eot');
    src: local('Aneba Neue Medium'), local('AnebaNeue-Medium'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue Regular';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Regular.eot');
    src: local('Aneba Neue'), local('AnebaNeue-Regular'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Regular.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Regular.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue UltraLight Italic';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLightitalic.eot');
    src: local('Aneba Neue UltraLight Italic'), local('AnebaNeue-UltraLightitalic'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLightitalic.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLightitalic.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLightitalic.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue SemiBold';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBold.eot');
    src: local('Aneba Neue SemiBold'), local('AnebaNeue-SemiBold'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBold.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBold.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aneba Neue UltraLight';
    src: url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLight.eot');
    src: local('Aneba Neue UltraLight'), local('AnebaNeue-UltraLight'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLight.woff2') format('woff2'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLight.woff') format('woff'),
        url('https://recursos.casaegaragem.com.br/c/site-2024/fonts/AnebaNeue-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

