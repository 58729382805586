main.page-category{
    >.container>h1{
        text-align: center;
        font-size: 20px;
        color: #1D2F4F;
		display: none;
    }
    section{
    	&[data-id="category-banner"]{
    		margin: 20px 0;
    		@include mobile{
    			margin-top: 0;
    			padding: 0 5px;
    		}
    		img{
    			border-radius: 10px;
    		}
    	}
    	&[data-id="breadcrumb"]{
    		@include mobile{
    			display: none;
    		}
    	}
        &[data-id="message"]{
        	@include mobile{
        		padding: 0 5px;
        	}
           p{
            color: #1E1E1E;
           }
        }
        &[data-id="content"]{
        	display: grid;
		    grid-template-columns: 250px 1fr;
		    gap: 20px;
		    
		    @include mobile{
		    	grid-template-columns: 1fr;
		    }

        	>.filters{
        		@include mobile{
        			display: none;
        		}
        		&.mobile-open{
				    display: block;
				    position: fixed;
				    top: 0;
				    left: 0;
				    z-index: 999;
				    width: 100%;
				    
				    transition: all .2s ease-in-out;
	                will-change: opacity,transform;
	                animation: Ab .3s cubic-bezier(.18,.89,.32,1.28);
	                -ms-transform-origin: top center;
	                transform-origin: top center;
				    
				    .overlay{
				    	position: absolute;
					    width: 100%;
					    height: 100%;
					    background-color: rgba(0, 0, 0, .6);
				    }
				    
				    .content{
				    	margin: 20px;
					    position: relative;
					    z-index: 2;
					    max-height: 600px;
					    overflow: auto;
				    }
        		}
        		>.content{
        			background-color: var(--shelf-card-bg);
				    -webkit-border-radius: var(--shelf-card-radius);
				    border-radius: var(--shelf-card-radius);
				    -webkit-box-shadow: var(--shelf-card-shadow);
				    box-shadow: var(--shelf-card-shadow);
				    padding: 10px;
				    
				    >h3{
				    	color: #FF5E00;
				    	text-align: center;
				    	font-size: 20px;
				    	margin-bottom: 20px;
				    	
				    	@include mobile{
				    		text-align: left;
				    	}
				    	
				    	.close{
			    		    float: right;
			                svg{
			                    width: 18px;
			                    height: 18px;
			                    path{
			                        fill: var(--header-menu-close) !important;
			                    }
			                }
			            }

				    }
				    
				    svg{
	    				fill: #1D2F4F;
	    				transition: all .2s;
	    			}
				    
				    li[id]{
				    	display: flex;
					    gap: 5px;
					    align-items: center;
					    &:not(:last-child){
					    	margin-bottom: 10px;
					    }
				    }
				    
	        		>ul >li{
	        			span{
	        				color: #1E1E1E;
	        				text-transform: capitalize;
	        			}
	        			&.active{
	        				svg{
	        					transform: rotate(180deg);
	        				}
	        				>div{
	        					background-color: #EBEBEB;
	        				}
	        				>ul{
	        					margin: 10px;
	        				}
	        			}
	        			&:not(.active){
	        				>ul{
		        				display: none;
		        			}
	        			}
	        			&:not(:last-child){
	        				margin-bottom: 5px;
	        			}
	        			>div{
	        				display: flex;
	        				justify-content: space-between;
	        				align-items: center;
	        				transition: all .2s ease-out;
	    				    &:hover{
	    				    	background-color: #EBEBEB;
	    				    }
						    padding: 5px 10px;
						    border-radius: 5px;
						    color: #1E1E1E;
						    cursor: pointer;
	        			}
	        		}
	        		>ul{
	    			    margin: 0;
	    			    >li{
	    			    	margin-bottom: 10px;
	    			    }
	        		}
	        		ul,li{
	        			list-style: none;
	        			
	        			label{
	        				background: transparent !important;
	        			}
	        		}
        		}
			    
        	}
        	
        	>.products{
        		h3{
        			margin-left: 10px;
        			@include mobile{
        				font-size: 16px;
        				margin-bottom: 10px;
        			}
        			
        			button{
			    		float: right;
					    margin-right: 10px;
					    background-color: #FF5E00;
					    color: #fff;
					    border: none;
					    padding: 10px 20px;
					    border-radius: 13px;
					    font-family: var(--font-primary-bold);
					    font-size: 16px;
					    position: relative;
					    top: -12px;
					    cursor: pointer;
			    	}
        		}
        		
        		.swiper{
        			@include mobile{
        				width: 100%;
        			}
        			
        			.swiper-button-prev,
    				.swiper-button-next{
    					display: none;
    				}
        		}
        		div.products[data-type=static] .list-products{
        			grid-template-columns: repeat(4,1fr);
        			@include mobile{
        				grid-template-columns: repeat(2,1fr);
        				gap: 10px;
        			}
        		}
        		
        	}
        }
        &[data-id="pagination"]{
        	margin-bottom: 40px;
        	li,ul{
        		list-style: none;
        	}
        	
        	ul{
        		display: flex;
        		gap: 10px;
        		align-items: center;
        		justify-content: center;
        		
        		li{
        			&.sel a{
        				border-width: 2px;
        			}
        			a{
        			transition: all .2s ease-out;
        				&:hover{
        					background-color: #1D2F4F;
        					color: #fff;
        					border-color: #1D2F4F;
        				}
	        			width: 35px;
	        			height: 35px;
        				display: flex;
        				justify-content: center;
        				align-items: center;
        				border: 1px solid #1D2F4F;
        				color: #FD5F00;
        				border-radius: 5px;
        			}
        		}
        	}
        }
    }
}