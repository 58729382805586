@keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  main section[data-id=shelf]>div.products:not(.loaded){
      display: block !important;
  }
  
  body{
      &.fbits-home,
          &#bodyProduto{
          section[data-id=shelf]>div.products:not(.loaded) .swiper [data-componente-listaspot-id]{
              display: flex;
              width: 100%;
              
              @include desktop{
                  gap: 30px;
              }
              
              @include mobile{
                  gap: 20px;
              }
              
              .fbits-item-lista-spot{
                  @include desktop{
                      flex: 0 1 25%;
                  
                      &:nth-child(n+6) {
                          display: none;
                      }
                  }
                  
                  @include mobile{
                      flex: 0 1 50%;
                  
                      &:nth-child(n+3) {
                          display: none;
                      }
                  }
              }
          }
      }
      
      &:not(.fbits-home){
          div.products:not(.loaded) .swiper [data-componente-listaspot-id]{
              display: grid;
              @include desktop{
                  grid-template-columns: repeat(4,1fr);
                  gap: 30px;
              }
              
              @include mobile{
                  grid-template-columns: repeat(2,1fr);
                  gap: 10px;
              }
              
          }
      }
  }
  
  main section[data-id=shelf]>div.products:not(.loaded) .swiper [data-componente-listaspot-id]{
      
      .fbits-item-lista-spot{
          .product{
              @include desktop{
                  height: 440px;
              }
              
              @include mobile{
                  height: 360px;
              }
              background-color: #f6f7f8;
              background-size: 800px 104px;
              
              animation-duration: 2s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: placeHolderShimmer;
              animation-timing-function: linear;
              background-color: #f6f7f8;
              background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
              background-size: 800px 104px;
              position: relative;
          }
          
      }
  }

  body{
	&.fbits-home{
		@include desktop{
			.banner_items .desktop{
				display: block !important;
				 .fbits-componente-banner{
				 	
				 	.fbits-banner-item {
					 	&:nth-child(n+2) {
	                		display: none;
	                	}
				 	}
				 }
			}
		}
		
		@include mobile{
			.banner_items .mobile{
				display: block !important;
				 .fbits-componente-banner{
				 	
				 	.fbits-banner-item {
					 	&:nth-child(n+2) {
	                		display: none;
	                	}
				 	}
				 }
			}
		}
	}
}