main.pdp{
    section{
        &[data-id="breadcrumb"]{
            @include mobile{
                color: #1D2F4F;
                a{
                    color: #1D2F4F;
                }

                ol{
                    justify-content: flex-start;
                }
            }
        }
        &[data-id="product"]{
            @include desktop{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 30px;
            }
    
            .side__a{
                >h1,
                >div.product__name,
                >div.product__name > h1{
                    font-size: 24px;
                    font-weight: bold;
                    color: #1D2F4F;
    
                    @include mobile{
                        padding: 0 10px;

                        margin-top: 0;
                        margin-bottom: 10px;
                        h1{
                            margin-bottom: 10px;
                        }
                    }
    
                    margin-top: 0;
                    margin-bottom: 40px;

                    @include desktop{
                        margin-bottom: 10px;
                    }
                    
                    &.desktop{
                        // @include mobile{
                        //     display: none;
                        // }
                        @include mobile{
                            display: block;
                        }
                    }
                    &.mobile{
                        display: none;
                        margin-bottom: 10px;
                        @include desktop{
                            display: none;
                        }
                    }
                }
    
                .product__details{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 20px;
                    padding: 0 20px;

                    @include desktop{
                        padding-left: 0;
                    }
    
                    .store_reviews{
                        display: flex;
                        align-items: center;
                        gap: 10px;
    
                        .total{
                            color: #4F4F4F;
                            font-size: 15px;
                        }
                    }
                    .product__ref{
                        background-color: #F5F5F5;
                        color: #4F4F4F;
                        display: inline-block;
                        font-size: 16px;
                        padding: 5px 10px;
                        border-radius: 5px;

                        .fbits-sku{
                            font-weight: inherit;
                            display: inline-block;
                        }
                    }
                }
    
                .product__gallery{
                    .swiper .swiper-button-next, 
                    .swiper .swiper-button-prev{
                        &:not([aria-disabled="true"]){
                            color: #FF5E00;
                        }
                    }
                    .swiper .swiper-button-next{
                        right: 0 !important;
                    } 
                    .swiper .swiper-button-prev{
                        left: 0 !important;
                    }
    
                    .swiper .swiper-button-next:after, .swiper .swiper-button-prev:after{
                        @include mobile{
                            font-size: 16px !important;
                        }
    
                        @include desktop{
                            font-size: 28px !important;
                        }
                    }
                    @include desktop{
                        width: 550px;
                    }
    
                    img{
                        max-width: 100%;
                        max-height: 100%;
                        width: 100%;
                    }
                    .gallery__desktop{
                        .dots{
                            margin-top: 10px;
                        }
                        @include desktop{
                            .swiper__image-thumbs img{
                                height: 400px;
                                object-fit: contain;
                            }
                        }

                        @include mobile{
                            display: none;
                        }
                        .dots{
                            margin: 20px 0;
                        }
    
                        .swiper__image-all .swiper-slide img{
                            border: 2px solid #F2F0F0;
                            border-radius: 15px;
                        }
    
                        .swiper__image-all .swiper-slide-thumb-active img{
                            border-color: #1D2F4F;
                        }
                    }
                    .gallery__mobile{
                        .dots{
                            margin-top: 10px;
                        }
                        @include mobile{
                            .swiper__image-thumbs img{
                                height: 375px;
                                object-fit: contain;
                            }
                        }
                        @include desktop{
                            display: none;
                        }
    
                        width: 100%;
                        max-width: 100%;
                        overflow: hidden;
                    }
                }
            }
    
            .side__b{
                >.interno{
                    background-color: #F5F5F5;
                    border-radius: 10px;
                    padding: 30px 40px;

                    @include mobile{
                        margin: 10px;
                        padding: 10px;
                    }
    
                    >.prices{
                        > .price{
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            line-height: 1;
                            .flags{
                                .flag{
                                    border-radius: var(--shelf-card-tarja-radius);
                                    display: inline-block;
                                    font-size: 14px;
                                    font-weight: 700;
                                    line-height: 1;
                                    padding: 4px 10px;
                                    color: #fff;
                                }
                                .detail{
                                    color: #4F4F4F;
                                    font-size: 16px;
                                }
                            }
                            .price_de{
                                color: #4F4F4F;
                                font-size: 16px;
                                >span{
                                    font-size: 17px;
                                    margin-left: 10px;
                                    color: #4F4F4F;
                                    text-decoration: line-through;
                                }
                            }
                            .price_por{
                                font-size: 15px;
                                >span{
                                    font-size: 35px;
                                    margin-left: 10px;
                                    vertical-align: sub;
                                    line-height: 1;
                                    font-family: var(--font-primary-bold);
                                }
                                >sub{
                                    font-size: 14px;
                                }
                            }
                            .parcel{
                                font-size: 14px;
                                color: #4F4F4F;
                            }
                            &.normal{
                                border-bottom: 3px solid #CCCCCC;
                                padding-bottom: 15px;
                                margin-bottom: 10px;
                                .flags{
                                    .flag{
                                        background-color: var(--shelf-card-taja1-bg);
                                    }
                                }
                                .price_por{
                                    color: #1D2F4F;
                                }
                            }
                            &.atacado{
                                .flags{
                                    .flag{
                                        background-color: var(--shelf-card-taja2-bg);
                                    }
                                }
                                .price_por{
                                    color: var(--mini-product-add-price-b);
                                }
                            }
                        }
                    }

                    >.variants_store{
                        display: none;
                    }

                    >.variants{
                        >label{
                            font-size: 18px;
                        }
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;
                            margin-top: 10px;
                            
                            li{
                                border: 1px solid #1d2f4f;
                                color: #1d2f4f;
                                padding: 5px;
                                border-radius: 3px;
                                cursor: pointer;
                                transition: all .2s ease-out;
                                
                                &.indisponivel{
                                    cursor: no-drop;
                                    opacity: .8;
                                    border-color: #898585 !important;
                                    color: #898585;
                                }
                                
                                &.active{
                                    background-color: #1d2f4f;
                                    color: #fff;
                                }
                            }
                        }
                    }
    
                    >.cta{
                        margin-top: 30px;
                        display: grid;
                        grid-template-columns: 120px 1fr;
                        gap: 15px;
                        align-items: center;
    
    
                        .qtd{
                            position: relative;
                            >button{
                                cursor: pointer;
                                position: absolute;
                                background-color: #1D2F4F;
                                border: none;
                                color: #fff;
                                font-family: var(--font-primary-bold);
                                padding: 5px 10px;
                                border-radius: 5px;
                                cursor: pointer;
                                transition: all .2s ease-out;
                                display: flex;
                                justify-content: content;
                                align-items: center;
                                &:hover{
                                    opacity: .95;
                                }
                                &.minus{
                                    left: 0;
                                    top: 1px;
                                }
                                &.plus{
                                    right: 0;
                                    top: 1px;
                                }
                            }
                            input{
                                background-color: transparent;
                                border: none;
                                color: #1D2F4F;
                                font-family: var(--font-primary-bold);
                                text-align: center;
                                outline: none;
                                font-size: 22px;
                                width: 120px;
                            }
                        }
                        .add-to-cart{
                            background-color: #5db232;
                            border: none;
                            -webkit-border-radius: 15px;
                            border-radius: 15px;
                            color: #fff;
                            font-family: var(--font-primary-bold);
                            font-size: 24px;
                            padding: 14px 30px;
                            text-transform: uppercase;
                            cursor: pointer;
                            @include mobile{
                                font-size: 16px;
                            }
                        }
                    }

                    .shipping{
                        >.shipping_store{
                            display: none;
                        }
                        margin-top: 10px;
                        label{
                            color: #202225;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-bottom: 10px;
                            font-size: 18px;
                            font-family: var(--font-primary);
                            font-weight: bold;
                        }
    
                        .wrap{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            @include mobile{
                                flex-wrap: wrap;
                            }
                            a{
                                color: #4F4F4F;
                                font-size: 12px;
                            }
                            .calculator{
                                position: relative;
                                display: inline-block;
                                border: 2px solid #4F4F4F;
                                border-radius: 10px;
                                width: 100%;
                                max-width: 300px;
    
                                input{
                                    border: none;
                                    padding: 10px 10px;
                                    border-radius: 10px;
                                    font-family: var(--font-primary);
                                    color: #757575;
                                    outline: none;
                                    font-size: 16px;
                                    width: 100%;
                                    &::placeholder{
                                        color: #757575;
                                    }
                                }
    
                                button{
                                    position: absolute;
                                    right: 5px;
                                    top: 6px;
                                    background-color: #4F4F4F;
                                    color: #fff;
                                    border-radius: 5px;
                                    padding: 5px 10px;
                                    border: none;
                                    font-family: var(--font-primary-bold);
                                    cursor: pointer;
                                }
                            }
    
    
                        }

                        .results{
                            table#opcoes-frete{
                                float: unset;
                            }
                        }
                    }
    
                    .benefits{
                        margin-top: 30px;
                        display: grid;
                        grid-template-columns: repeat(3,1fr);
                        gap: 20px;
                        text-align: center;
                        margin-bottom: 20px;
                        @include mobile{
                            gap: 5px;
                        }
    
                        .benefit{
                            background-color: #EEEDED;
                            color: #4F4F4F;
                            padding: 10px;
                            @include mobile{
                                padding: 2px;
                            }
                            border-radius: 10px;
                            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    
                            svg{
                                margin-bottom: 0px;
                                height: 60px !important;
                            }
    
                            strong{
                                font-size: 13px;
                                margin: 0;
                                padding: 0;
                                display: block;
                                margin-bottom: 10px;
                            }
    
                            p{
                                margin: 0;
                                padding: 0;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        &[data-id="tabs"]{
            margin-top: 30px;
            margin-bottom: 30px;
            @include mobile{
                padding: 0 15px;
            }
            .tab-items{
                list-style: none;
                border: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                li{
                    padding: 5px 10px;
                    border-radius: 5px;
                    transition: all .3s ease-out;
                    cursor: pointer;
                    &:hover{
                        opacity: .9;
                    }
                    &:not(.active) svg{
                        transform: rotate(180deg);
                    }
                }
            
                
                li[data-id="description"]{
                    background-color: #FD5F00;
                    color: #fff;
                }

                li[data-id="details"]{
                    background-color: #1D2F4F;
                    color: #fff;
                    
                }
            }
            >div{
                margin-top: 30px;
                color: #4F4F4F;
                font-size: 15px;
                line-height: 1.5;
                font-family: var(--font-primary);
                display: none;
            }
        }
        &[data-id="shelf"]{
            .dots{
                display: block !important;
                margin-top: 10px;
            }
        }
        &[data-id="message"]{
            @include mobile{
                padding: 0 10px;
            }
        }
    }
}