.new-summary > h4,
#div-endereco-body>h4,
.new-checkout .fbits-info-item .usuario-nome{
	font-family: var(--font-default) !important;
    font-weight: 500 !important;
    font-size: 24px !important;
}

.novo-topo{
	.topheader{
		background-color: var(--header-topside-bg);
	    border-top: 9px solid var(--header-topside-border);
	    color: var(--header-topside-color);
	    font-family: var(--font-primary);
	    padding: .6rem 0;
	    position: relative;
	    height: 50px;
	    display: none;
	}
	
	.navigation{
		-webkit-box-shadow: 2.4px 2.4px 3.2px rgba(0, 0, 0, .15);
    	box-shadow: 2.4px 2.4px 3.2px rgba(0, 0, 0, .15);
    	margin-bottom: 10px;
    	
    	.container{
    		display: flex;
    		justify-content: space-between;
    		align-items: center;
    		
    		&:after,
    		&:before{
    			display: none;
    		}
    		
    		@media(max-width: 992px){
				gap: 10px;
			}
    		
    		.logov2{
			    bottom: 0;
			    margin-right: 25px;
			    position: relative;
			    
			    img{
			    	// max-width: 100%;
			    	// max-height: 100%;
			    	// width: auto !important;
		    	    max-width: 100%;
				    max-height: 100%;
				    width: 120px;
				    margin: 10px 0;
			    }
			}
			
			.contact{
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				
				>div{
					font-family: var(--font-primary);
					display: flex;
					flex-direction: column;
					line-height: 1;
					color: #1D2F4F;
					
					@media(max-width: 992px){
						font-size: 12px;
					}
					
					a{
						color: #1D2F4F;
						font-weight: bold;
						
					}
				}
			}
			
			.seal{
				@media(max-width: 992px){
					svg{
						width: 80px !important;
					}
				}
			}
			
			a.back-to-store{
				@media(max-width: 992px){
					svg{
						width: 80px !important;
					}
				}
			}
    	}
	}
	
	
}

.cart-tabs{
	ul{
		background-color: #fff;
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    list-style: none;
	    display: flex;
	    justify-content: space-between;
	    padding: 10px 0;
	    
	    @media(max-width: 992px){
    	    margin: 0 10px;
	    }
	    
	    li{
	    	line-height: 35px;
	    	font-family: var(--font-primary);
	    	flex: 0 1 25%;
	    	color: #868686;
	    	text-align: center;
	    	font-weight: bold;
	    	
	    	font-size: 18px;
	    	
	    	&:not(:last-child){
	    		border-right: 2px solid #7D7D7D;
	    	}
	    	
	    	&.active{
	    		color: #FD5F00;
	    		@media(max-width: 992px){
	    			flex: 0 1 100%;
	    			border: none !important;
	    		}
	    	}
	    	
	    	&:not(.active){
	    		@media(max-width: 992px){
	    			display: none;
	    		}
	    	}
	    }
	}
}

.rodape-checkout{
	display: none !important;
}

.fbits-carrinho{
	

	[data-frete-item]{
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	
	.container{
		padding: 0;
	}
	
	ul#carrinho-produtos{
		border: none;
		background-color: #fff;
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    padding: 0;
	    margin-top: 10px;
	    
	    &::before {
		    content: "Itens prontos para chegar até você!";
		    color: #1D2F4F;
		    font-weight: bold;
		    margin-left: 15px;
		    margin-top: 15px;
		    display: block;
		    font-size: 16px;
		}
	    
	    li{
	    	border-color: #e1e1e1 !important;
	    	
	    	.fbits-etiqueta-atacado{
	    		display: none !important;
	    	}
	    	
	    	.fbits-responsive-carrinho-item-total:before{
	    		display: none ;
	    	}
	    	
	    	.fbits-responsive-carrinho-item-total{
	    		min-height: unset !important;
	    	}
	    	
	    	.subqtd{
				font-size: 16px;
			    position: absolute;
			    right: -150px;
			    top: 5px;
			    color: #4F4F4F;
			    text-align: left;
			    width: 140px;
			}
	    	
	    	a.btn.btn-danger.icon.remover{
	    		&::before{
		    		display: none;
		    	}
		    	
		    	margin-right: 20px;
	    	}
	    	
	    	.fbits-responsive-carrinho-item-titulo{
	    		align-items: flex-start;
	    		
	    		@media(min-width: 992px){
	    			width: 70% !important;
	    		}
	    		
		    	.fbits-responsive-carrinho-item-imagem{
		    		img{
	    			    border: 1px solid #CECCCC;
	    				border-radius: 15px;
		    		}
		    	}
		    	
		    	.fbits-responsive-carrinho-item-descricao{
		    		a{
		    			color: #1D2F4F;
					    font-family: var(--font-primary);
					    font-size: 13px;
					    text-transform: capitalize;
					    font-weight: 600;
		    		}
		    		
		    		a.arrow{
	    			    height: 20px;
					    width: 20px;
					    padding: 0;
					    line-height: 1;
					    display: flex;
					    align-items: center;
					    background-color: #4F4F4F;
					    border-radius: 5px;
		    			font-size: 0;
		    			&::before{
		    				font-size: 8px;
		    				color: #fff;
		    			}
		    		}
		    		
		    		.fbits-responsive-carrinho-item-quantidade{
		    			padding-left: 0;
		    			width: 100%;
					    text-align: left;
					    float: unset;
					    margin: 0 !important;
					    padding: 0;
		    			.input-group{
			    			position: relative;
			    			margin: 0;
			    			
			    			input{
			    				float: unset;
		    					width: 90px !important;
							    color: #4F4F4F;
								font-weight: bold;
			    			}
			    			
			    			>.input-group-btn{
			    				&:nth-child(1){
			    					position: absolute;
								    z-index: 99;
								    left: 5px;
								    top: 6px;
								    display: block;
								    float: unset;
								    width: auto;
								}
			    				&:nth-child(3){
				    				position: absolute;
								    z-index: 99;
								    right: 5px;
								    top: 6px;
								    display: block;
								    float: unset;
								    width: auto;
			    				}
			    			}
			    		}
		    		}
		    		
		    		.fbits-responsive-carrinho-item-total{
		    			.price{
		    				display: flex;
		    				gap: 5px;
		    				align-items: center;
		    				&.normal{
		    					font-family: var(--font-primary);
		    					
		    					.flags .flag{
	    						    display: inline-block;
								    background-color: rgba(79, 79, 79, 0.8);
								    color: #fff;
								    font-weight: bold;
								    padding: 2px 5px;
								    border-radius: 10px;
								    font-size: 11px;
		    					}
		    					
		    					.price_product{
		    						color: #1D2F4F;
		    						font-size: 10px;
		    						font-weight: bold;
		    						
		    						span{
		    							font-size: 18px;
		    						}
		    					}
		    				}
		    				&.atacado{
		    					font-family: var(--font-primary);
		    					
		    					.flags .flag{
	    						    display: inline-block;
								    background-color: #FD5F00;
								    color: #fff;
								    font-weight: bold;
								    padding: 2px 5px;
								    border-radius: 10px;
								    font-size: 11px;
		    					}
		    					
		    					.price_product{
		    						color: #FD5F00;
		    						font-size: 10px;
		    						font-weight: bold;
		    						
		    						span{
		    							font-size: 18px;
		    						}
		    						
		    						sub{
		    							font-size: 10px;
		    							color: #4F4F4F;
		    							margin-left: 5px;
		    							margin-bottom: 5px;
		    						}
		    					}
		    				}
		    			}
		    		}
		    		
		    		.qtdCarrinho{
		    			border-color: #CECCCC !important;
		    		}
		    	}
		    	
	    		
	    	}
	    	
	    	.fbits-responsive-carrinho-item-total{
	    		text-align: left;
	    		padding-left: 0;
	    		&:not(.loaded){
		    		display: none;
		    	}
		    	&.loaded{
		    		margin-bottom: 10px;
		    		margin-top: 10px;
		    	}
	    	}
	    	
	    }
	}
	
	.cart-area{
		@media(min-width: 992px){
			display: grid;
	    	grid-template-columns: 60% 1fr;
	    	margin: 0;
	    	gap: 20px;
			&:before,
			&:after{
				display: none;
			}
			>div{
				width: 100%;
				// padding: 0;
				&.cart-products{
					padding: 0;
				}
			}
		}
		
		@media(max-width: 992px){
			margin: 0 10px;
			
			.cart-products{
				padding: 0;
			}
		}
	}
	
	.cart-details,
	.row>.col-lg-3.col-md-3.col-sm-12.col-xs-12{
		font-family: var(--font-primary);
		font-weight: bold;
		& *{
			font-family: var(--font-primary);
		}
		input,input::placeholder,button{
			font-family: var(--font-primary);
		}
		
		.btcontinuar{
			display: none;
		}
		
		background-color: #fff;
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    margin-top: 20px;
	    
		#form-frete{
			.fbits-responsive-carrinho-frete-texto{
			    display: flex;
			    color: #1D2F4F;
			    gap: 7px;
			    align-items: center;
				margin-bottom: 0;
    			padding-left: 0;
			}
			
			.fbits-responsive-carrinho-frete-calculo{
				padding: 0;
			}
			
			#naoSeiCep{
				display: none;
			}
			
			.aviso-frete{
			    border: none;
			    margin: 0;
				margin-top: 10px;
			    
				p{
				    font-size: 12px;
				    padding: 0;
				    margin: 0;
				    color: #868686;
				    font-weight: 400;
				}
			}
			
			.fbits-responsive-carrinho-frete-bloco{
				padding: 0 !important;
			}
			
			.fbits-responsive-carrinho-frete-input{
				position: relative;
				
				input#btnCalcularFrete{
				    position: absolute;
				    right: 5px;
				    top: 5px;
				    width: 45px;
				    height: 25px;
				    padding: 0;
				    background-color: #1D2F4F !important;
				    color: #fff !important;
				    border: none !important;
				    border-radius: 5px !important;
				    opacity: 1 !important;
				}
				
				input#txtCEP{
					width: 100% !important;
				    border-color: #CECCCC !important;
				    color: #757575;
				    font-size: 14px;
				}
			}
			
		}
		.totals{
			// display: none;
			.sub-total-lemoon{
				border: none !important;
				color: #1e1e1e !important;
				padding: 0;
				margin-bottom: 20px;
			}

			.sub-total-lemoon *{
				color: #1e1e1e !important;
			}
		}
		.new-totals{
			display: none !important;
			margin-bottom: 60px;
			>div{
				display: flex;
				justify-content: space-between;
				line-height: 50px;
				display: flex;
    			justify-content: flex-end;
				
				>span{
					font-weight: bold;
					color: #1E1E1E;
				}
				
				>span{
					flex: 0 1 30%;
					width: 30%;
				}
				
				>div{
					flex: 0 1 70%;
					width: 70%;
					text-align: right;
				    position: relative;
				    color: #1E1E1E;
				    font-weight: bold;
				    display: flex;
				    justify-content: flex-end;
					
					
					.componente-parcelamento{
				        justify-content: flex-end;
					    position: absolute;
					    right: 0;
					    bottom: 0px;
					    display: flex;
					    gap: 5px;
					    width: 100%;
					    line-height: 1;
					    color: #1E1E1E;
					    font-size: 12px;
					}
					
					&::before{
						content: "R$";
						display: inline-block;
						margin-right: 4px;
					}
				}
				
				&:not(:last-child){
					border-bottom: 1px solid #e1e1e1;
				}
			}
		}
		
		.benefits{
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 20px;
            text-align: center;
            margin-bottom: 20px;
            @media(max-width: 992px){
                gap: 5px;
            }

            .benefit{
                background-color: #EEEDED;
                color: #4F4F4F;
                padding: 10px;
                @media(max-width: 992px){
                    padding: 2px;
                }
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

                svg{
                    margin-bottom: 0px;
                    height: 60px !important;
                }

                strong{
                    font-size: 13px;
                    margin: 0;
                    padding: 0;
                    display: block;
                    margin-bottom: 10px;
                }

                p{
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }
            }
        }
	}
	
}

.fbits-login{
	
	// font-family: var(--font-primary);
	// *:not(i){
	// 	font-family: var(--font-primary) !important;
	// }
	
	// #inputLoginEmail{
	// 	font-weight: bold;
	// }
	
	.box-login{
		-webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
	
	#inputLoginEmail{
		border-color: #868686;
    	border-radius: 10px;
	}
	
	.fbits-box-login .btn.btn-success{
		background-color: #1D2F4F;
	    font-family: var(--font-primary);
	    width: 60%;
	    font-size: 15px;
	}
	
	.fbits-title-login{
		color: #1D2F4F !important;
		font-weight: bold;
	}
	
	#fbits-googleplus-button{
		border-color: #FD5F00;
	    color: #FD5F00;
	    width: 210px;
	    border-radius: 10px;
	    display: block;
	    margin: 0 auto;
	    margin-top: 20px;
	    font-family: var(--font-primary);
	}
	
	
	#btnFacebook{
	    border-color: #1D2F4F;
	    color: #1D2F4F;
	    width: 210px;
	    border-radius: 10px;
	    font-family: var(--font-primary);
	}
	
	.info-do-login{
		font-family: var(--font-primary);
	    background-color: transparent;
	    border: none;
	    color: #4F4F4F;
	    span{
	    	color: #868686;
	    }
	}
}

div.pergunta{
	text-transform: uppercase;
    color: #1D2F4F;
    font-family: var(--font-primary);
    
    @media(min-width: 992px){
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    padding: 30px 360px !important;
	    max-width: 1140px !important;
    	
    }
    
	div.ola{
		text-transform: uppercase;
    	color: #1D2F4F;
    	
    	&:after{
    		text-transform: none;
		    font-size: 14px;
		    color: #4F4F4F;
    	}
	}
	
	.questao{
		background-color: #1D2F4F;
	    color: #fff;
	    border-radius: 5px;
	    position: relative;
	    margin-top: 50px;
	    
		&::before{
	        color: #868686;
		    position: absolute;
		    top: -46px;
		    left: 0;
		    background-color: transparent;
	        text-transform: none;
		}
	}
	
	.respostas{
		a[name="loginPerguntasResposta"]{
			border-radius: 5px;
		    border-color: #868686;
		    color: #1E1E1E;
		}
	}
	
	.linkSenha a{
		color: #FD5F00;
	    font-size: 14px;
	    margin-top: 25px;
	    display: block;
	    text-transform: uppercase;
	    font-weight: bold;
	}
}

.topo-carrinho{
	opacity: 0 !important;
}


.content.minhaconta{
	.center{
		>.sideBar{
			display: none !important;
		}
		>.new-sidebar{
			font-family: var(--font-primary);
			
			display: inline-block !important;
			width: 25%;
		    margin-right: 15px;
		    position: relative;
		    
		    @media(max-width: 992px){
		    	width: 100%;
		    	
		    	&:not(.active) {
		    		padding-bottom: 15px;
		    		.list{
				    	display: none;
				    }
		    	}
		    }
		    
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		    
		    &.active > h2 svg{
		    	transform: rotate(-90deg);
		    }
		    
		    >h2{
		    	color: #1D2F4F;
			    font-size: 18px;
			    margin-top: 15px;
			    margin-left: 20px;
			    font-weight: bold;
			    position: relative;
			    
			    @media(min-width: 992px){
			    	svg{
			    		display: none;
			    	}
			    }
			    
			    @media(max-width: 992px){
			    	svg{
			    		transition: all .3s ease-out;
			    		position: absolute;
					    right: 20px;
					    top: 20px;
					    transform: rotate(90deg);
			    	}
			    }
		    }
		    
		    >strong.username.show{
		    	color: #1D2F4F;
			    font-weight: 400;
			    margin-left: 20px;
			    font-size: 14px;
			    font-weight: bold;
		    }
		    
		    >.list{
		    	border-top: 1px solid #e1e1e1;
			    margin-top: 15px;
			    padding-top: 20px;
			    
			    >h3{
		    	    color: #1E1E1E;
				    font-size: 14px;
				    font-weight: bold;
				    margin: 0 20px;
				    
				    &:not(:first-child){
				    	margin-top: 20px;
				    }
			    }
			    
			    .options{
			    	padding: 0;
    				margin-top: 10px;
    				
    				>.option{
    					border-bottom: 1px solid #e1e1e1;
					    padding: 0 20px;
					    padding-bottom: 10px;
					    
					    &:not(:first-child){
					    	margin-top: 10px;
					    }
					    
					    &.active{
					    	color: #FD5F00 !important;
					    	
					    	a{
					    		color: #FD5F00;
					    		
						    	>span{
						    		color: #1D2F4F;
						    	}
					    	}
					    	
					    	
					    	svg{
					    		path{
					    			stroke: #FD5F00 !important;
					    		}
					    	}
					    }
					    
					    >a{
					    	position: relative;
						    display: flex;
						    flex-direction: column;
						    
						    font-size: 14px;
						    
						    color: #1D2F4F;
						    
						    font-weight: bold;
						    
						    >span{
						    	font-size: 12px;
						    }
						    
						    >svg{
						    	position: absolute;
							    right: -5px;
							    top: 10px;
							    width: 18px !important;
							    height: 18px !important;
						    }
					    }
    				}
			    }
		    }
		}
		
		input[type=text], input[type=email], input[type=date], input[type=password], input[type=number], input[type=tel]{
			border: none;
		    border-bottom: 1px solid #e1e1e1;
		    height: 25px;
		    color: #868686;
		    padding: 0;
		    
		    font-family: var(--font-primary);
		}
		
		
		div#main{
			font-family: var(--font-primary);
			font-weight: bold;
			
			-webkit-border-radius: 10px;
		    border-radius: 10px;
		    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		    
		    padding: 20px;
		    
		    @media(max-width: 992px){
		    	margin: 0;
		    	margin-top: 30px;
		    	width: 100%;
		    	max-width: 100%;
		    }
		    
		    h3.title{
				font-size: 24px;
			    color: #1D2F4F;
			    font-weight: bold;
			    margin-bottom: 30px;
			}
			
			.forminline > label{
				color: #1D2F4F;
				font-family: var(--font-primary);
				font-weight: bold;
			}
			
			.fbits-minhaconta-order{
				width: 100%;
				-webkit-border-radius: 10px;
			    border-radius: 10px;
			    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			    border: none;
				
				.fbits-minhaconta-btn-details{
					display: none;
				}
				.fbits-minhaconta-box-text{
					display: none;
				}
				.fbits-minhaconta-box-spotlight{
					color: #4F4F4F;
				}
				.fbits-minhaconta-box-title{
					color: #1D2F4F;
				    border: none;
				    font-weight: bold;
				    font-family: var(--font-primary);
				    font-size: 11px;
			        min-height: 20px;
				}
				
				.fbits-minhaconta-col{
					@media(min-width: 992px){
						width: calc(97% / 4) !important;
	    			}
				    border-color: #7D7D7D;
				    
				    @media(max-width: 992px){
		    			width: 50% !important;
		    		}
					
					&:nth-child(4){
						border-right: none;
					}
				}
				
				
				.custom-order-shipping{
					width: 100% !important;
				    line-height: 1;
				    margin: 0;
				    border-top: 1px solid #e1e1e1;
				    padding: 0 15px;
				    padding-top: 20px;
				    box-sizing: border-box;
				    
				    >.fbits-minhaconta-box{
				    	padding: 0;
					    padding-bottom: 25px;
					    display: flex;
					    align-items: center;
					    
					    >.fbits-minhaconta-box-title{
					    	margin: 0;
						    padding: 0;
						    min-height: unset;
					    }
					    
					    >.fbits-minhaconta-box-info-adicional{
				    	    margin: 0;
						    text-transform: uppercase;
						    color: #757575;
						    margin-left: 15px;
						    
						    &::before{
						    	content: "-";
    							margin-right: 10px;
						    }
					    }
				    }
				}
			}
			
			.fbits-minha-conta-detalhe-pedido,
			.minhaconta-produtos-pedido{
				border: none;
			    margin: 0;
			    padding: 0;
			    margin-top: 40px;
			    
			    @media(max-width: 992px){
		    		text-align: center;
		    	}
			    
			    // -webkit-border-radius: 10px;
			    // border-radius: 10px;
			    // -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			    // padding: 10px;
			    
			    .col:before{
			    	// display: none;
			    	border: none;
			    	text-transform: uppercase;
			    	color: #1D2F4F;
			    }
			    
			    .col:after{
			    	@media(max-width: 992px){
		    			display: none;
		    		}
			    }
			    
			    @media(max-width: 992px){
				    .minha-conta-produtos{
				    	>div{
				    		text-align: center;
				    		display: flex;
				    		justify-content: space-between;
				    	}
				    }
				    
				    .detalhe-quantidade{
				    	max-width: 100%;
				    }
		    	
		    	}
			    
			    
			    .minha-conta-produtos .col.detalhe-nome:before{
			    	content: "PRODUTOS DO PEDIDO";
			    	@media(max-width: 992px){
		    			display: none;
		    		}
			    }
			    .minha-conta-produtos .col.detalhe-quantidade:before{
			    	content: "QUANTIDADE";
			    }
			    .minha-conta-produtos .col.detalhe-unitario:before{
			    	content: "PREÇO UNITÁRIO";
			    }
			    .minha-conta-produtos .col.detalhe-valor:before{
			    	content: "VALOR TOTAL";
			    }
			    
			    @media(min-width: 992px){
				    .col.detalhe-nome,
				    .col.detalhe-quantidade,
				    .col.detalhe-unitario,
				    .col.detalhe-valor{
				    	border-bottom: 1px solid #e1e1e1;
				    	margin-bottom: 10px;
				    }
				    
		    	
		    	}
		    	
		    	@media(max-width: 992px){
		    		.col.detalhe-unitario{
		    			background-color: transparent !important;
		    			margin: 0;
		    			padding: 0;
		    		}
		    		
				    .col.detalhe-valor{
				    	width: 100% !important;
				    	margin: 0 !important;
				    	padding: 0 !important;
				    	border: none;
				    }
				    
				    .col.detalhe-unitario{
				    	display: none !important;
				    }
	    		}
			    
			    
			    .col.detalhe-img {
			    	margin-right: 15px;
			    	img {
					    max-width: 75px;
					    height: auto;
					    border: 1px solid #CECCCC;
					    border-radius: 15px;
					}
			    }
			    
			    .minha-conta-produtos .col.detalhe-status{
			    	&:before{
			    	    content: "STATUS:";
					    margin: 0;
				    }
				    
			    	flex: 100%;
				    text-align: left;
				    margin-left: 95px;
				    display: flex;
				    gap: 10px;
				    
				    @media(max-width: 992px){
		    			margin: 0;
		    			width: 100%;
		    			justify-content: space-between;
		    			padding: 0;
		    		}
				    
				    span{
				    	text-transform: uppercase;
				    }
			    }
			    
			    .minhaconta-produtos-pedido{
			    	margin: 0;
			    	
			    	>h3{
			    		margin-bottom: 15px;
			    		&:before{
			    			display: none;
			    		}
			    	}
			    	
			    	.minha-conta-produtos{
			    		
			    	}
			    }
			}
		}
	}
}

.new_checkout2 {
	color: #1D2F4F;
	*{
		font-weight: 500;
	}
	
	input[type=text], input[type=email], input[type=date], input[type=password], input[type=number], input[type=tel]{
			border: none;
		    border-bottom: 1px solid #e1e1e1;
		    height: 25px;
		    color: #868686;
		    padding: 0;
		    border-radius: 0 !important;
		    background-color: transparent;
		    
		    font-family: var(--font-primary);
		    box-shadow: none;
		}
	
	.steps{
		display: none;
	}
	
	.success-fixed{
		&:not(.top){
			position: relative !important;
			top: 0 !important;
		}

		&.top{
			position: fixed !important;
			top: 130px !important;
			width: 100%;
			max-width: 390px !important;
		}
	}
		
	.box-default{
		font-family: var(--font-primary);
		
		-webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    border: none !important;
	    
		h5{
			display: none;
		}
		
		div[id]{
			display: block !important;

			.tab-pane:not(.active){
				display: none !important;
			}
		}
		
		&.box-entrega{
			
			.fbits-envio-container{
				position: relative;
				display: flex !important;
				margin: 0 10px !important;
				flex-direction: column !important;
			}
			
			
		}
		
		.item-envio.item-frete-option{
			position: relative;
			background: #fff !important;
			
			&:not([data-frete-item-tipo="Retirada"]){
				.nome-item-prazo{
				    position: absolute;
				    left: 80px;
				    bottom: 11px;
				    color: #868686;
				}
				
			}
			
			&.selected{
				margin-left: 10px !important;
			}

		}
		
		.nome-item-envio{
			color: #1D2F4F;
		    display: flex;
		    align-items: center;
		    gap: 10px;
		    
			i{
				display: none;
			}
			
			input{
				height: 26px;
			}
			
			
		}
		.nome-item-valor{
			font-size: 16px;
		}
		
		.formas-pagamento{
			ul.formas-pagamento{
				display: flex;
				flex-direction: column;
				
				a{
					display: flex;
				    align-items: center;
				    gap: 15px;
				}
				
				a#g4882-tab:after {
				    content: "Aprovação Imediata";
				    background-color: #DBF7DF;
				    color: #1D6540;
				    padding: 10px;
				    position: absolute;
				    right: 0;
				    border-radius: 5px;
				}
				
				a#g9023-tab:after {
				    content: "Aprovação em até 3 dias úteis";
				    background-color: rgba(253, 95, 0, 0.15);
				    color: #FD5F00;
				    padding: 10px;
				    position: absolute;
				    right: 0;
				    border-radius: 5px;
				}
			}
		}
	}
	
	
	ul.fbits-info-pessoal{
		i{
		    font-size: 19px;
		}
		div{
			margin-bottom: 10px;
		}
	}
	
	.info-retirada{
		margin-top: 20px;
	}
	
	.fbits-trocar-endereco{
		border: none !important;
	    background-color: #1D2F4F;
	    color: #fff;
	    text-shadow: none !important;
	    font-size: 14px;
	    width: auto !important;
	}
	
	.fbits-adicionar-endereco{
	    background-color: #fff;
	    color: #FD5F00;
	    border: 2px solid #FD5F00 !important;
	    text-shadow: none !important;
	    font-size: 14px;
	    width: auto !important;
	    
	    &:hover{
	    	background-color: #FD5F00;
	    	color: #fff !important;
	    }
	}
	
	.pay-info{
	    display: flex;
	    align-items: center;
	    gap: 10px;
	    color: #1D2F4F;
	}
	
	.fbits-produtos-container > h5{
		display: none !important;
	}
	
	.new-summary{
		*{
			line-height: 1 !important;
			margin: 0;
			padding: 0;
			font-family: var(--font-primary);
			// font-weight: bold;
		}
		
		>h4{
			padding: 15px;
		}
		
		.infos{
			display: flex;
		    justify-content: space-between;
		    width: 100%;
		    margin-bottom: 20px;
		    >span{
		    	padding-left: 15px;
		    	color: #FD5F00;
		    	font-size: 16px;
		    }
		    >div{
		    	padding-right: 15px;
		    	*{
		    		color: #1D2F4F;
			    	font-family: var(--font-primary-bold);
		    	}
		    	
		    	strong{
		    		font-size: 16px;
		    	}
		    }
		}
		
		.total-tabs{
			>li{
				&.total .a,
				&.total .b{
					font-weight: bold;
					font-size: 16px;
				}
				&.shipping .b{
					font-size: 15px;
				}
				border-top: 1px solid #e1e1e1;
				
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				height: 50px;
				
				.a{
					padding-left: 15px;
					font-size: 16px;
				}
				
				.b{
					padding-right: 15px;
					color: #FD5F00;
					font-size: 11px;
				}
			}
		}
	}
	
	span.fbits-responsive-carrinho-subtotal-valor{
		line-height: 1;
		margin: 0;
	}
	
	#div-produtos,
	.fbits-responsive-carrinho-detalhes{
		display: none !important;
	}
	
	.success-fixed{
		-webkit-border-radius: 10px;
	    border-radius: 10px;
	    -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	    height: 360px !important;
	    
	    .fbits-produtos-container{
	    	border: none !important;
	    	border-color: transparent !important;
	    }
	}
	
	.item-endereco:hover{
		background: transparent !important;
	}
	
}

@media(max-width: 992px){
	section.fbits-nc-carrinho.new-checkout{
        margin-top: -20px;
	}
	.checkout_v2{
	    margin: 0 5px !important;
	}
	.new_checkout2 .box-default .formas-pagamento ul.formas-pagamento a#g9023-tab:after,
	.new_checkout2 .box-default .formas-pagamento ul.formas-pagamento a#g4882-tab:after{
		font-size: 11px;
	}
}

form#formEndereco{
	display: block !important;
}

#formEndereco .btn{
	position: relative !important;
	bottom: 0 !important;
}

.item-endereco[data-selecionado="true"]{
    border-left: 7px solid #5DB232 !important;
    padding-left: 10px;
}

#div-endereco-body > h4{
	padding-left: 20px;
}

@media(max-width: 992px){
	div#fbits-frete-body{
		margin: 10px !important;
	}
	
	.new-checkout .item-endereco{
	    margin: 0 10px;
	    margin-bottom: 10px;
	}
}

div#div-formapagamento-body > div .si-actions{
	margin-top: 20px;
	padding-bottom: 0 !important;
	padding-left: 0 !important;
}

.si-actions{
	display: flex;
	gap: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	button{
		padding: 5px 10px;
		border: none;
		border-radius: 7px;
		font-size: 14px;
		cursor: pointer;
		
		&.back{
			
		}
		
		&.next{
			background-color: #5DB232;
			color: #fff;
		}
		
	}
}




div#div-usuario-body .nav.nav-tabs{
	justify-content: flex-start;
}

div#div-usuario-body li a{
	padding: 5px 20px;
    border-radius: 5px !important;
    background-color: #4F4F4F;
    color: #fff !important;
    font-size: 14px;
}

div#div-usuario-body li.active a{
	background-color: #FD5F00;
	border-radius: 5px !important;
	color: #fff !important;
}

.form_tel_cpf{
	flex-direction: column;
}

.form_tel_cpf .divTEL{
	margin: 0 !important;
}

.tab-pane#juridica:not(.active){
	display: none !important;
}

.termine-outras-etapas{
	margin: 0 10px;
}

.box-default.box-identity.checkout-active .si-actions{
	display: none;
}

#modalBuscaCEP{
	display: none !important;
}

#div-endereco-body>h4{
	padding: 0 !important;
}

div#div-endereco-body{
	padding: 0;
}

.fbits-envio-container > div.termine-outras-etapas{
	display: none !important;
}

.new-checkout .msg-avancar{
	text-align: left !important;
	margin-top: 0 !important;
}

.fbits-confirmacao{
	font-family: var(--font-primary);
	*{
		font-family: var(--font-primary) !important;
	}
	.method-pix{
		background-color: #1d2f4f;
	}
	
	ul{
		list-style: none;
	}

	.novo-topo {
		background-color: #fff;
		.navigation{
			margin-bottom: 0;
	
			.container{
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
	}
}

.box-default.box-forma-pagamento .si-actions{
	margin-top: 10px;
	padding-bottom: 0;
}

.box-default.box-forma-pagamento .termine-outras-etapas{
	margin-top: 10px;
}

.fbits-nc-carrinho-col{
	padding-right: 5px !important;
    padding-left: 5px !important;
}

body .new-checkout .btn-success.completar-etapas-para-finalizar{
    font-family: var(--font-primary-bold);
    font-size: 14px !important;
}

div#fbits-frete-body:before {
    content: "Frete";
    font-family: var(--font-default) !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    display: block;
    margin-left: 0;
    margin-top: 20px;
    line-height: 1;
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
    padding-left: 10px;
}

span[data-frete-item-nome]{
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 600;
}

.new-checkout .nome-item-prazo{
	font-size: 12px;
    line-height: 1.1;
}

ul.formas-pagamento > li{
	border-top: 1px solid #E0DFDF;
}

ul.formas-pagamento > li:last-child:after{
    content: "Pagamento via boleto leva em média 3 dias úteis para ser confirmado, e o prazo de entrega começa a contar a partir dessa confirmação, por isso, a previsão de entrega pode ser alterada.";
    background-color: #F4F5F5;
    display: block;
    padding: 5px;
    color: #4F4F4F;
    font-size: 11px;
    margin-left: 45px;
    border-radius: 5px;
}

div[data-gateway-nome="Cartão"] .forminline input {
    border: none;
    border-bottom: 1px solid #4D5F6D;
    text-align: left;
    width: auto;
}

.formas-pagamento .forminline label{
	display: block;
    width: 100%;
    margin: 0;
    font-size: 16px;
}

div.formas-pagamento{
	&:before{
		content: "Como você gostaria de pagar?";
	    font-family: var(--font-default) !important;
	    font-weight: 500 !important;
	    font-size: 24px !important;
	    display: block;
	    margin-left: 0;
	    line-height: 1;
	    padding-left: 10px;
	    padding-top: 10px;
	}
	span.checkout-mensagem-forma-pagamento{
		display: none !important;
	}
}

p.subtitle-desconto{
    font-family: var(--font-default) !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    margin-top: -15px;
}

div.temCupom{
    color: #FD5F00 !important;
}

/*
.fbits-responsive-carrinho-frete-bloco[style="margin-top: 10px; padding: 0px 5px;"] .form-inline.fbits-responsive-carrinho-frete-input{
	display: grid;
	grid-template-columns: 1fr 50px;
	gap: 10px;

	input{
		width: 100% !important;
		border-radius: 5px !important;
		background-color: #fff !important;
	}

	.btnLimpar{
		background-color: #FD5F00;
		border-radius: 5px !important;
		border: none !important;
	}
}
*/