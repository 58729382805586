body{
	.container-fluid{
		 max-width: 1224px !important;
	    width: 100% !important;
	    padding: 0;
	    margin: 0 auto;
	    margin-bottom: 30px;

		@include mobile{
			padding: 0 10px;
		}
	}
	
	&.fbits-rastreio{
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    background-color: var(--bg-color);
		    -webkit-box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
		    font-family: var(--font-primary);
		    font-weight: 400;
		    text-rendering: optimizeLegibility;
		    
		.container{
		    max-width: 1224px !important;
		    width: 100% !important;
		    padding: 0;
		    margin: 0 auto;
		}
		
		.logo{
			margin-right: 0 !important;
		}
		#imgLogo{
			display: none;
		}
		
		@include mobile{
			.list-group-item{
				border: none !important;
				padding-left: 0 !important;
				 *{
				 	padding: 0 !important;
				 }
			}
		}
		
		form{
			&#rastrear_form{
				>div{
					float: unset;
					display: flex;
				    justify-content: center;
				    @include mobile{
				    	flex-wrap: wrap;
				    	gap: 20px;
				    	justify-content: flex-start;
				    	flex-direction: column;
				    	
				    	>div{
				    		padding: 0;
				    	}
				    	
				    	#rastrearDiv{
				    		padding: 0;
				    	}
				    }
					>div{
					    float: unset;
					    
					}
				}
				
				
			}
		}
		
		.order-status{
			margin-bottom: 60px !important;
		}
		
		.well{
			border: none !important;
			box-shadow: none !important;
		}
		
		.collapse {
			border: none !important;
			padding: 0 !important;
		}
		
		#showHideDivOne ul br{
			display: none !important;
		}
	}
}


.footer-seo {
	*{
		color:  #1e1e1e;
	}
	.fbits-conteudo-item{
		margin-bottom: 80px;
		hr {
		    display: block;
		    margin-block-start: 0.5em;
		    margin-block-end: 0.5em;
		    margin-inline-start: auto;
		    margin-inline-end: auto;
		    unicode-bidi: isolate;
		    overflow: hidden;
		    border-style: inset;
		    border-width: 1px;
		}
		p{
			display: block;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			unicode-bidi: isolate;
		}
	}
	
	.catalog-desc{
		background-color: #F44336;
	    padding: 5px 10px;
	    border-radius: 5px;
	    color: #ffffff;
	}
}