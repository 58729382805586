.menu__oppened{
    overflow: hidden;
}

div.main__header{
    @include mobile{
        display: none !important;
    }
    background-color: var(--header-topside-bg);
    border-top: 4px solid var(--header-topside-border);
    color: var(--header-topside-color);
    font-family: var(--font-primary);
    padding: .3rem 0;
    position: relative;

    .top__side{
        font-family: var(--font-primary-bold-italic);
        text-align: center;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        svg{
            margin: 0 10px;
        }

        span{
            color: var(--header-topside-color-alt);
        }
    }
}

div.main__menu{
    @include mobile{
        display: none !important;
    }
    height: 74px;
    border-bottom: 1px solid var(--header-menu-divider);

    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        gap: 20px;

        .logo{
            position: relative;
            bottom: 15px;
            margin-right: 25px;
            
        }
        .search{
            position: relative;
            width: 100%;
            max-width: 620px;

            input{
                width: 100%;
                padding: 9px 15px;
                border: 1px solid var(--header-search-border);
                border-radius: 15px;
                background-color: var(--header-search-bg);
                color: var(--header-search-text);
                font-family: var(--font-primary);
                font-weight: 400;
                font-size: 14px;
                box-shadow: var(--header-search-shadow);
                &::placeholder{
                    color: var(--header-search-text); 
                }
                &:focus{
                    outline: none;
                }
                

            }

            button{
                border: none;
                background-color: transparent;
                position: absolute;
                right: 15px;
                top: 8px;

                svg{
                    fill: var(--header-search-icon);
                    path{
                        fill: var(--header-search-icon);
                    }
                }
            }
        }
        .side_options{
            display: flex;
            gap: 15px;

            .side{
                position: relative;
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 14px;
                color: var(--header-sideopt-text);
                a{
                    color: var(--header-sideopt-text);
                }
                
                line-height: 1.2;

                .info{
                    display: flex;
                    flex-direction: column;
                }

                svg{
                    width: 26px;
                    height: 26px;
                    fill: var(--header-sideopt-icon);
                    
                    path{
                        fill: var(--header-sideopt-icon);
                    }
                }

                >span{
                    position: relative;
                    .cart-qtd{
                        background-color: var(--header-sideopt-cartqtd-bg);
                        color: var(--header-sideopt-cartqtd-txt);
                        width: 20px;
                        height: 20px;
                        display: block;
                        position: absolute;
                        top: -11px;
                        right: -8px;
                        border-radius: 100px;
                    }
                }

                &.cart{
                    cursor: pointer;
                }
            }
        }
    }
}

div.main__navigation{
    @include icons;

    @include mobile{
        display: none !important;
    }

    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

     ul.navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        li{
            list-style: none;
            position: relative;

            &.cnpj {
                a{
                    margin: 10px 0;
                    background-color: var(--header-nav-cnpj-bg);
                    color: var(--header-nav-cnpj-text);
                    border-radius: var(--header-nav-active-radius);
                    line-height: 1.1;
                    padding: 0px 10px;
                    font-size: 14px;
                    gap: 0;

                    &::before{
                        height: 42px;
                        width: 40px;
                        background-size: cover;
                    }
                }
            }

            a{
                color: var(--header-nav-submenu-text);
                transition: all .2s ease-out;
                text-align: left;
            }

            ul[class^="level-"]{
                background-color: var(--header-nav-submenu-bg);
                position: absolute;
                width: 300px;
                left: 0;
                top: 40px;
                display: none;
                z-index: 100;
                &:hover{
                    display: block;
                }

                transition: all .2s ease-in-out;
                will-change: opacity,transform;
                animation: Ab .3s cubic-bezier(.18,.89,.32,1.28);
                -ms-transform-origin: top center;
                transform-origin: top center;

                &.level-three{
                    left: unset;
                    top: 0;
                    right: -200px;
                    position: absolute;
                    width: 200px;
                }
                
                li{
                    &.has-children{
                        &:hover > ul{
                            display: block;
                        }
                        >a{
                            &:after{
                                content: "";
                                width: 14px;
                                height: 6px;
                                display: block;
                                background-image: var(--header-nav-chevron-active);
                                transform: rotate(-90deg);
                            }
                        }
                    }
                    &:hover >a{
                        background-color: var(--header-nav-hover-bg);
                        color: var(--header-nav-hover-text);
                    }
                    a{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 10px;
                    }
                }
            }
        }
        > li{
            margin: 0;
            list-style: none;
            transition: all .2s ease-out;

            &.has-children{
                text-align: center;
                &:hover >ul{
                    display: block;
                }
                >a{
                    &:after{
                        content: "";
                        width: 14px;
                        height: 6px;
                        display: block;
                        background-image: var(--header-nav-chevron-default);
                    }
                }
            }

            &.destaque{
                &.has-children{
                    a{
                        &:after{
                            background-image: var(--header-nav-chevron-active);
                        
                        }
                    }
                }
                >a{
                    background-color: var(--header-nav-active-bg);
                    color: var(--header-nav-active-text);
                    border-radius: var(--header-nav-active-radius);

                    
                }
            }

            >a{
                color: var(--header-nav-text);
                font-size: 16px;
                display: inline-flex;
                justify-content: center;
                gap: 5px;
                align-items: center;
                padding: 10px 10px;
            }
        }
     }
}

div.main__menumobile{
    @include desktop{
        display: none;
    }

    .menu__items.fixed{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        background-color: #fff;

        transition: all .2s ease-out;

        .menu{
            padding-top: 10px;
        }
    }

    .sidetop{
        background-color: var(--header-topside-bg);
        color: var(--header-topside-color);
        font-family: var(--font-primary-bold-italic);
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        padding: 10px 5px;

        svg{
            margin: 0 2px;
            vertical-align: sub;
            path{
                fill: var(--header-topbar-color-svg) !important;
            }
        }

        span{
            color: var(--header-topside-color-alt);
            margin: 0 2px;
        }
    }

    .menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px;
        padding-bottom: 10px;
        background-color: var(--header-menu-bg-mobile);

        .logo img{
            @include preload-image;
        }

        .cart{
            span{
                position: relative;
                .cart-qtd{
                    background-color: var(--header-sideopt-cartqtd-bg);
                    color: var(--header-sideopt-cartqtd-txt);
                    height: 20px;
                    width: 20px;
                    display: block;
                    position: absolute;
                    top: -11px;
                    right: -8px;
                    border-radius: 100px;
                }
            }
            svg{
                width: 26px;
                height: 26px;
                fill: var(--header-sideopt-icon);
                
                path{
                    fill: var(--header-sideopt-icon);
                }
            }
        }
    }

    .search{
        position: relative;
        width: 100%;
        padding: 0 20px;
        padding-bottom: 20px;

        form{
            position: relative;
        }

        input{
            width: 100%;
            padding: 8px 15px;
            border: 2px solid var(--header-search-mobile-border);
            border-radius: 15px;
            background-color: var(--header-search-mobile-bg);
            color: var(--header-search-mobile-text);
            font-family: var(--font-primary);
            font-weight: 400;
            font-size: 14px;
            &::placeholder{
                color: var(--header-search-mobile-text); 
            }
            &:focus{
                outline: none;
            }
            

        }

        button{
            border: none;
            background-color: transparent;
            position: absolute;
            right: 15px;
            top: 8px;

            svg{
                fill: var(--header-search-mobile-icon);
                path{
                    fill: var(--header-search-mobile-icon);
                }
            }
        }
    }
    
}

div.main__menumobile_items{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(calc(-100% - 10px));
    box-shadow: 0 3px 12px rgba(0, 0, 0, .16);
    z-index: 22;
    transition: .3s ease-out;
    
    &.show{
        transform: translateX(0);
    }
    .overlay{
        position: absolute;
        z-index: -1;
        background-color: var(--header-menu-overlay-mobile);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .content{
        background-color: var(--header-menu-bg-mobile);
        color: var(--header-menu-text-mobile);
        max-width: 80%;
        height: 100%;
        position: relative;
        overflow: auto;
        
        .account{
            padding: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--header-menu-border-mobile);

            .status{
                display: flex;
                svg{
                    width: 36px;
                    height: 36px;
                    background: var(--header-menu-mob-svg-bg);
                    border-radius: 50px;
                    margin-right: 16px;
                    fill: var(--header-menu-mob-svg-fill);
                    border: 2px solid var(--header-menu-mob-svg-border);
                }

                >div{
                    font-size: .775rem;
                    >strong{
                        display: block;
                        font-size: .925rem;
                    }

                    a{
                        color: var(--header-menu-text-mobile);
                        text-decoration: underline;
                    }
                }
            }
            .close{
                svg{
                    width: 18px;
                    height: 18px;
                    path{
                        fill: var(--header-menu-close) !important;
                    }
                }
            }
        }

        .navigation{
            overflow: auto;
            padding: 20px;
            // max-height: 400px;
            li{
                list-style: none;
            }
            ul{
                display: none;
                transition: .3s ease-out;
            }
            ul.level-three{
                padding-left: 20px;
            }
            a{
                line-height: 2.2;
                color: var(--header-menu-items-text-mobile);
            }

            >li{
               line-height: 1.3; 
            }

            li{
                &.has-children{
                    >a{
                        &:after{
                            content: "";
                            width: 14px;
                            height: 6px;
                            display: block;
                            background-image: var(--header-nav-chevron-default);
                            transform: rotate(0deg);
                            width: 25px;
                            height: 25px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            transition: .3s ease-out;
                        }
                    }
                }
                &.active{
                    >a::after{
                        transform: rotate(180deg) !important;
                    }

                    >ul{
                        display: block;
                        background-color: var(--header-menu-items-sub-bg);
                        border-radius: var(--header-menu-items-sub-radius);
                        a{
                            line-height: 1.5;
                        }
                    }
                }
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 10px;
                }
            }
        }
    }
}

div.product_added{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(calc(150% - 10px));
    box-shadow: 0 3px 12px rgba(0, 0, 0, .16);
    z-index: 22;
    transition: .3s ease-out;
    display: flex;
    justify-content: flex-end;
    
    &.show{
        transform: translateX(0);
        
    }
    .overlay{
        position: absolute;
        z-index: -1;
        background-color: var(--header-menu-overlay-mobile);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .content{
        background-color: var(--header-menu-bg-mobile);
        color: var(--header-menu-text-mobile);
        width: 495px;
        height: 100%;
        position: relative;
        overflow: auto;
        padding: 20px 15px;

        img:not([src]){
            opacity: 0;
            visibility: hidden;
        }

        &.loading {
            overflow: hidden;
            .loading{
                display: block;
            }
        }
        .loading{
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, .8);
            z-index: 99;
            >div{
                animation:swiper-preloader-spin 1s infinite linear
            }
        }
        
        .header{
            display: flex;
            gap: 20px;
            align-items: center;
            margin-bottom: 20px;
            .close{
                width: 25px;
                height: 25px;
                cursor: pointer;
                path{
                    fill: var(--mini-product-add-close-svg) !important;
                }
            }

            h2{
                color: var(--mini-product-add-title);
            }
        }

        >.cart__empty{
            display: none;
            font-size: 22px;
            text-align: center;
            margin: 6rem auto;
        }

        .cart__items{
            .cart__item{
                margin-bottom: 20px;
                display: flex;
                gap: 10px;
                position: relative;

                @include mobile{
                    flex-wrap:wrap;
                }

                img{
                    width: 86px;
                    height: 86px;
                    border: var(--mini-product-add-border);
                    border-radius: var(--mini-product-add-radius);
                    object-fit: cover;
                }

                .item__details{
                    >p{
                        max-width: 220px;
                        color: var(--mini-product-add-text-name);
                        line-height: 1.1;
                    }

                    .item__prices{
                        margin-top: 5px;
                        .flag{
                            border-radius: var(--shelf-card-tarja-radius);
                            padding: 5px 10px;
                            line-height: 1;
                            display: inline-block;
                            font-weight: bold;
                            font-size: 10px;
                        }
                        .price{
                            font-weight: bold;
                            font-size: 14px;
                        }
                        .item__price{
                            &:not(:last-child){
                                margin-bottom: 5px;
                            }
                            &.normal{
                                .flag{
                                    background-color: var(--shelf-card-taja1-bg);
                                    color: var(--shelf-card-tarja1-text);
                                }
                                .price{
                                    color: var(--mini-product-add-price-a);
                                }
                            }
                            &.atacado{
                                .flag{
                                    background-color: var(--shelf-card-taja2-bg);
                                    color: var(--shelf-card-tarja2-text);
                                }
                                .price{
                                    color: var(--mini-product-add-price-b);
                                }
                                >small{
                                    font-size: 8px;
                                    color: var(--mini-product-add-text-un);
                                    @include mobile{
                                        display: block;
                                        text-align: left;
                                        margin-top: 5px;
                                    }
                                    @include desktop{
                                        display: block;
                                        margin-left: 11px;
                                    }
                                }
                            }
                        }
                    }
                }

                .o__qtd{
                    label{
                        margin-bottom: 10px;
                        display: block;
                    }
                    input{
                        border: none;
                        outline: none;
                        padding: 10px;
                        border-radius: var(--mini-product-add-btn-area-radius);
                        font-family: var(--font-primary);
                        font-weight: 400;
                    }
                    >div{
                        position: relative;
                        border: var(--mini-product-add-btn-area-border);
                        border-radius: var(--mini-product-add-btn-area-radius);
                        
                        button{
                            border: none;
                            background-color: var(--mini-product-add-btn-bg);
                            color: var(--mini-product-add-btn-text);
                            border-radius: var(--mini-product-add-btn-radius);
                            font-family: var(--font-primary);
                            font-weight: bold;
                            padding: 5px 8px;
                        }
                    }

                    >div{
                        width: 120px;
                    }
    
                    label{
                        color: var(--mini-product-add-text-un);
                        font-family: var(--font-primary);
                        font-weight: bold;
                        line-height: 27px;
                        span{
                            font-weight: normal;
                        }
                    }
    
                    input{
                        text-align: center;
                        font-weight: bold;
                        width: 100%;
                        color: var(--mini-product-add-btn-bg);
                        font-size: 18px;
                        padding-right: 12px;
                    }
    
                    button{
                        position: absolute;
                        top: 9px;
                        &.minus{
                            left: 5px;
                        }
                        &.plus{
                            right: 5px;
                        }
                    }
                }

                .cart_delete_item{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    
                    @media(max-width: 992px){
                        padding-top: 35px;
                        margin-left: 10px;
                    }
                    
                    @media(min-width: 992px){
                        position: absolute;
                        right: 0;
                        top: 7px;
                    }
                }

                >button{
                    background-color: transparent;
                    border: var(--mini-product-add-cta-border);
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                    color: var(--mini-product-add-cta-bg);
                    font-size: 12px;
                    font-weight: 700;
                    height: 40px;
                    padding: 5px 20px;
                    -webkit-transition: all .2s ease-out;
                    -o-transition: all .2s ease-out;
                    -moz-transition: all .2s ease-out;
                    transition: all .2s ease-out;
                    flex: 1 0 auto;
                    margin-top: 25px;

                    @include mobile{
                        flex: 0 1 120px;
                        font-size: 11px;
                        margin: 0;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding: 4px 10px;
                        height: 35px;
                    }
                }
            }
        }

        .item__others{
            display: flex;
            gap: 25px;

            svg{
                vertical-align: sub;
                path{
                    fill: var(--mini-product-add-label) !important;
                }
            }

            >div{
                label{
                    margin-bottom: 10px;
                    display: block;
                }
                input{
                    border: none;
                    outline: none;
                    padding: 10px;
                    border-radius: var(--mini-product-add-btn-area-radius);
                    font-family: var(--font-primary);
                    font-weight: 400;
                }
                >div{
                    position: relative;
                    border: var(--mini-product-add-btn-area-border);
                    border-radius: var(--mini-product-add-btn-area-radius);
                    
                    button{
                        border: none;
                        background-color: var(--mini-product-add-btn-bg);
                        color: var(--mini-product-add-btn-text);
                        border-radius: var(--mini-product-add-btn-radius);
                        font-family: var(--font-primary);
                        font-weight: bold;
                        padding: 5px 8px;
                    }
                }
            }

            .o__qtd{
                >div{
                    width: 120px;
                }

                label{
                    color: var(--mini-product-add-text-un);
                    font-family: var(--font-primary);
                    font-weight: bold;
                    line-height: 27px;
                    span{
                        font-weight: normal;
                    }
                }

                input{
                    text-align: center;
                    font-weight: bold;
                    width: 100%;
                    color: var(--mini-product-add-btn-bg);
                    font-size: 18px;
                    padding-right: 12px;
                }

                button{
                    position: absolute;
                    top: 9px;
                    &.minus{
                        left: 5px;
                    }
                    &.plus{
                        right: 5px;
                    }
                }
            }

            .o_shipping{
                input{
                    height: 42px;
                }

                button{
                    position: absolute;
                    right: 10px;
                    top: 8px;
                }
            }
        }

        .divider{
            margin: 20px 0;
            display: flex;
        }

        .subtotals{
            margin-bottom: 20px;
            padding: 20px 30px;
            border: var(--mini-product-add-btn-area-border);
            border-radius: var(--mini-product-add-btn-area-radius);
            color: var(--mini-product-add-subtotal-text);
            >div{
                line-height: 3;
                border-bottom: var(--mini-product-add-subtotal-border);
                display: flex;
                justify-content: space-between;
                &.discounts{
                    >span:not(.value){
                        color: var(--mini-product-add-discounts-a);
                    }
                    .value{
                        color: var(--mini-product-add-discounts-b);
                    }
                }
                &.total{
                    >span:not(.value){
                        font-size: 20px;
                    }
                    .value{
                        position: relative;
                        >small{
                            position: absolute;
                            width: 250px;
                            right: 0;
                            bottom: 5px;
                            line-height: 1;
                            font-size: 12px;
                            text-align: right;
                            line-height: 1;
                        }
                    }
                }
                &:last-child{
                    border: none;
                }
            }
        }

        .cta{
            margin-bottom: 20px;
            a{
                padding: 20px;
                display: block;
                text-decoration: none;
                background-color: var(--mini-product-add-cta-bg);
                color: var(--mini-product-add-cta-txt);
                border-radius: var(--mini-product-add-cta-radius);
                text-align: center;
                font-weight: bold;
                font-size: 20px;

            }
        }

        .recomendations{
            >h3{
                text-align: left;
                color: var(--mini-product-add-subtotal-text);
                margin: 30px 0;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

}

span.cart-qtd::after {
    content: attr(data-qtd);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    width: 100%;
    height: 100%;
    padding-top: 2px;
}