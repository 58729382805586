.swiper{
    .swiper-button-prev,
    .swiper-button-next{
        color: var(--swiper-arrows-color);
        &::after{
            font-size: var(--swiper-arrows-size);
        }
    }

    .dots{
        text-align: center;
        @include desktop{
            margin-top: 10px;
        }
        

        .swiper-pagination-bullet{
            transition: all .2s ease-out;
            width: var(--swiper-dots-active-size);
            height: var(--swiper-dots-active-size);
            border: 2px solid var(--swiper-dots-border-color);
            opacity: 1;
            &:not(.swiper-pagination-bullet-active){
                background-color: var(--swiper-dots-bg);
            }
            &.swiper-pagination-bullet-active{
                width: var(--swiper-dots-active-width);
                background-color: var(--swiper-dots-bg-active);
                border-radius: var(--swiper-dots-active-size-radius) !important;
            }
        }
    }

    .swiper-wrapper{
        height: auto !important;
    }
}