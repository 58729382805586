main{
    &.cnpj-page{
        @include mobile{
            section:not([data-id="full-banner"]){
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        section[data-id="full-banner"]{
            @include desktop{
                margin-top: 60px;
            }

            img{
                width: 100%;
            }
        }

        h2{
            color: #1D2F4F;
            font-weight: bold;
            text-align: center;
            line-height: 70px;
            font-family: var(--font-primary-bold);
            font-size: 26px;

            @include mobile{
                line-height: 1;
                font-size: 16px;
                margin: 30px 0;
            }

            >span{
                color: #ff5e00;
            }
        }

        section[data-id="cards"]{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 30px;
            margin-bottom: 40px;

            @include mobile{
                grid-template-columns: 1fr;
            }
            .card{
                background-color: #F3F3F3;
                padding: 60px;
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 10px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                @include mobile{
                    padding: 10px;
                }

                h3{
                    color: #1D2F4F;
                    font-family: var(--font-primary-bold);
                    font-size: 28px;

                    @include mobile{
                        font-size: 22px;
                    }
                }
                p{
                    color: #5C5B5B;
                }

                a{
                    display: block;
                    background-color: #FD5F00;
                    color: #fff;
                    font-family: var(--font-primary);
                    line-height: 55px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    border-radius: 10px;

                    width: 100%;
                    max-width: 320px;
                    margin: 0 auto;

                    font-size: 17px;

                    transition: all 0.3s ease-out;

                    &:hover{
                       opacity: .85;
                    }

                    img{
                        width: 25px;
                    }
                }
            }
        }

        section[data-id="how-to-works"]{
            margin-bottom: 40px;
            >h3{
                font-family: var(--font-primary-bold);
                display: block;
                text-align: center;
            }

            .works{
                color: #1E1E1E;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 40px;

                @include mobile{
                    grid-template-columns: 1fr;
                    gap: 20px;
                }

                .work{
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    font-family: var(--font-primary-bold);
                    font-size: 17px;

                    .badge{
                        background-color: #fd5f00;
                        border-radius: 10px;
                        padding: 10px;
                        width: 90px;
                        height: 90px;

                        @include mobile{
                            width: 60px;
                            height: 60px;
                            padding: 5px;
                        }

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    span{
                        max-width: 60%;
                        font-family: "Aneba Neue Bold";
                    }
                }
            }
        }

        section[data-id="contact-list"]{
            margin-bottom: 60px;
            background-color: #F3F3F3;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding: 20px 40px;

            display: flex;

            @include mobile{
                flex-direction: column;
                padding: 10px;
            }

            >h3{
                font-family: var(--font-primary-bold);
                font-size: 26px;
                max-width: 210px;
                border-right: 2px solid #5C5A5A;
                padding-right: 30px;
                margin: 0;
                margin-right: 60px;
                line-height: .9;

                @include mobile{
                    max-width: unset;
                    font-size: 22px;
                    margin: 0;
                    padding: 0;
                    border: none;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }

            .list{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                @include mobile{
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    gap: 10px;
                    max-width: 80%;
                    margin: 0 auto;
                }

                .item{
                    @include mobile{
                        width: 100%;
                    }
                    a{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        color: #FD5F00;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}