main {
    section{
        >h1{
            color: var(--shelf-title-text);
            font-size: 20px;
            margin-bottom: 20px;
            text-align: center;
        }
        >h3{
            color: var(--shelf-title-text);
            font-size: 20px;
            margin-bottom: 20px;
            text-align: left;
            font-weight: 400;
        }
        &[data-id="full-banners"]{

            img{
                width: 100%;
            }
            
            @include mobile{
    
                .swiper-pagination-bullet{
                    border-width: 1px !important;
                    position: relative;
                    &.swiper-pagination-bullet-active{
                        border-color: var(--swiper-dots-border-color-active-mobile);
                    }
                    @include mobile{
                        &::before {
                            content: "";
                            width: 100%;
                            height: 40px;
                            position: absolute;
                            display: block;
                            top: -100%;
                        }
                    }
                }
                .dots{
                    position: relative;
                    z-index: 2;
                    top: -26px;
                }
            }

            .swiper-wrapper{
                height: auto !important;
            }
        }
        &[data-id="message"]{
            margin: 30px 0;
            p{
                color: var(--home-message-color);
                font-size: 14px;
                text-align: center;
                font-style: italic;
                line-height: 1.2;
                @include mobile{
                    font-size: 11px;
                }

                span{
                    color: var(--home-message-active);

                }

                a{
                    color: var(--home-message-link);
                }
            }
        }
        &[data-id="shelf"]{
            margin-bottom: 40px;
            @include mobile{
                padding: 0;
                .swiper{
                    padding: 10px 10px;
                    .swiper-button-next{
                        right: -9px;
                    }
                    .swiper-button-prev{
                        left: -9px;
                    }
                }
            }

            >div.products{
                &:not(.loaded){
                    display: none;
                }
                &[data-type="static"]{
                    .swiper-lazy-preloader{
                        display: none;
                    }
                    @include desktop{
                        .swiper-button-prev,
                        .swiper-button-next,
                        .dots{
                            display: none;
                        }

                        .list-products{
                            gap: var(--shelf-cards-gap);
                            display: grid;
                            grid-template-columns: repeat(5,1fr);
                        }
                    }

                    @include mobile{
                        .list-products{
                            gap: var(--shelf-cards-gap);
                            display: grid;
                            grid-template-columns: repeat(2,1fr);
                        }
                    }
                }
                &[data-type="carossel"]{
                    @include desktop{
                        // .swiper-button-prev,
                        // .swiper-button-next,
                        .dots{
                            display: none;
                        }

                        .swiper-button-prev{
                            left: -6px !important;
                        }
                        .swiper-button-next{
                            right: -6px !important;
                        }

                        .swiper-button-prev,
                        .swiper-button-next{
                            &::after{
                                font-size: 26px;
                            }
                        }
                    }
                    @include mobile{
                        .dots{
                            display: none;
                        }
                    }
                }
                
                @include desktop{
                    .swiper{
                        padding: 10px 20px;
                    }
                }

                .list-products{
                    margin: 0;
                }

                .product{
                    box-shadow: var(--shelf-card-shadow);
                    padding: 15px;
                    background-color: var(--shelf-card-bg);
                    border-radius: var(--shelf-card-radius);
                    box-sizing: border-box;
                    @include mobile{
                        padding: 10px
                    }

                    >a{
                        text-align: center;
                        display: block;
                        position: relative;
                        @include desktop{
                            height: 180px;
                        }

                        @include mobile{
                            height: 150px;
                        }


                        img{
                            max-width: 100%;
                            max-height: 100%;
                            margin: 0 auto;

                            &:not([src]) {
                                opacity: 0;
                            }
                            &.swiper-lazy {
                                opacity: 0;
                                transition: ease-in-out 0.2s;
                            }
                            &.swiper-lazy-loaded {
                                opacity: 1;
                            }
                        }
                    }

                    h2{
                        color: var(--shelf-card-name-color);
                        font-size: 16px;
                        font-weight: normal;
                        margin-bottom: 15px;
                        margin-top: 15px;

                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-height: 60px;

                        @include mobile{
                            min-height: 47px;
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }

                    .prices{
                        display: flex;
                        @include mobile{
                            flex-direction: column;
                        }

                        &.with{
                            flex-direction: row !important;
                            .price{
                                .details>span s{
                                    font-size: 11px;
                                }
                                .details>div>strong{
                                    font-size: 13px;
                                }
                                .details>div>strong>small{
                                    display: block;
                                }
                                .flag{
                                    padding: 5px 10px !important;
                                    font-size: 12px !important;
                                }
                                &.normal{
                                    border: none !important;
                                    margin: 0 !important;
                                    padding: 0 !important;
                                }
                            }
                        }
                        // gap: 15px;
                        &.without .price::after{
                            display: none !important;
                        }
                        @include mobile{
                            &.without .price.normal{
                                border: none;
                                padding: 0;
                                margin: 0;
                                margin-bottom: 15px;
                            }
                        }
                        .price{
                            flex: auto;
                            &.normal {
                                margin-right: 10px;
                                @include mobile{
                                    margin-right: 0;
                                    border-bottom: 1px solid #e1e1e1;
                                    margin-bottom: 10px;
                                    padding-bottom: 10px;
                                }
                                position: relative;
                                // &:after{
                                //     background-color: var(--shelf-divider-color);
                                //     content: "";
                                //     display: block;
                                //     height: 105px;
                                //     position: absolute;
                                //     right: -7px;
                                //     top: 0;
                                //     width: 1px;
                                //     @include mobile{
                                //         right: -11px;
                                //     }
                                // }
                                .flag{
                                    background-color: var(--shelf-card-taja1-bg);
                                    color: var(--shelf-card-tarja1-text);
                                }
                                .details>div{
                                    color: var(--shelf-card-price-full);
                                }
                            }
                            &.atacado{
                                margin-left: 10px;
                                @include mobile{
                                    margin-left: 0;
                                }
                                .flag{
                                    background-color: var(--shelf-card-taja2-bg);
                                    color: var(--shelf-card-tarja2-text);
                                }
                                .details>div{
                                    color: var(--shelf-card-price-full2);
                                }
                            }
                            .flag{
                                border-radius: var(--shelf-card-tarja-radius);
                                padding: 5px 10px;
                                line-height: 1;
                                display: inline-block;
                                font-weight: bold;
                                font-size: 12px;
                                @include mobile{
                                    font-size: 11px;
                                    padding: 5px 20px;
                                }
                            }

                            .details{
                                margin-top: 10px;
                                position: relative;

                                @include mobile{
                                    margin-top: 0;
                                }

                                >span{
                                    color: var(--shelf-card-price-of);
                                    font-size: 11px;
                                    >s{
                                        text-decoration: line-through;
                                    }

                                    @include mobile{
                                        font-size: 0;
                                        s{
                                            font-size: 12px;
                                        }
                                    }

                                    &.info{
                                        @include desktop{
                                            position: absolute;
                                            top: -12px;
                                            left: 0;
                                        }
                                    }
                                }

                                >div{
                                    margin-top: 0px;
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 30px;
                                    @include mobile{
                                        align-items: flex-start;
                                        margin-bottom: 0;
                                    }
                                    span{
                                        display: inline-block;
                                        font-size: 11px;
                                        width: 25px;
                                        line-height: 1.1;
                                        @include mobile{
                                            font-size: 9px;
                                            width: 19px;
                                            display: none;
                                        }
                                    }
                                    >strong{
                                        position: relative;
                                        font-size: 15px;
                                        @include mobile{
                                            font-size: 16px;
                                        }
                                        >small{
                                            font-weight: 400;
                                            position: absolute;
                                            bottom: -6px;
                                            right: 0;
                                            font-size: 11px;
                                            @include mobile{
                                                position: relative;
                                                bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .cta{
                        a{
                            background-color: var(--shelf-cta-bg);
                            color: var(--shelf-cta-text);
                            font-weight: 400;
                            display: block;
                            width: 100%;
                            line-height: 3;
                            border-radius: var(--shelf-cta-radius);
                            font-size: 14px;
                            text-align: center;
                            transition: all .2s ease-out;
                            font-family: var(--font-primary);

                            &.loading{
                                
                                position: relative;
                                height: 42px;
                                @include mobile{
                                    height: 31px;
                                }

                                .swiper-lazy-preloader{
                                    position: relative;
                                    border-color: #fff;
                                    border-top-color: transparent;
                                    width: 35px;
                                    height: 35px;
                                    margin: 0 auto;
                                    left: 0;
                                    top: 3px;

                                    @include mobile{
                                        top: 6px;
                                        border-width: 2px;
                                        width: 20px;
                                        height: 20px;
                                    }
                                }

                            }

                            &:hover{
                                opacity: .9;
                            }

                            @include desktop{
                                .b{
                                    display: none;
                                }
                            }

                            @include mobile{
                                line-height: 2.2;
                                .a{
                                    display: none;
                                }
                            }
                        }
                    }

                    
                }

            }
        }
        &[data-id="department"]{
            margin-bottom: 40px;
            .swiper-button-prev,
            .swiper-button-next,
            .dots{
                display: none;
            }
            @include mobile{
                .department-list{
                    padding: 0 10px;
                }
            }
            .item{
                a{
                    color: var(--department-text);
                    text-decoration: none;

                    >div{
                        position: relative;
                        text-align: center;
                        margin-bottom: 5px;
                        
                        img{
                            @include preload-image;
                        }
                    }

                    span{
                        color: var(--department-text);
                        display: block;
                        text-align: center;
                        font-size: 12px;
                    }
                }
            }
        }
        &[data-id="banner-md"]{
            margin-bottom: 40px;
            padding: 0 10px;

            img{
                border-radius: 13px;
            }
        }
        &[data-id="banner-tarja"]{
            margin-bottom: 40px;
            padding: 0 10px;
            
            text-align: center;
        }
        &[data-id="newsletter"]{
            padding: 0 10px;
            margin-bottom: 40px;
            .newsletter_content{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                @include mobile{
                    flex-direction: column;
                    text-align: center;
                }

                background-color: var(--newsletter-bg);
                box-shadow: var(--newsletter-shadow);
                padding: 10px;
                border-radius: var(--newsletter-radius);
                line-height: 1.2;

                h1{
                    color: var(--newsletter-title1);
                    font-size: 26px;
                    line-height: 1.1;
                }

                h2{
                    color: var(--newsletter-title2);
                    font-size: 26px;
                    max-width: 315px;
                    line-height: 1.1;
                }

                .btCadastrar{
                    @include desktop{
                        display: flex;
                        justify-content: center;
                        max-height: 46px;
                    }
                }

                br{
                    display: none !important;
                }

                form{
                    display: flex;
                    gap: 15px;
                    justify-content: space-between;
                    @include mobile{
                        flex-direction: column;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                    }
                    .newsletter-box{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include mobile{
                            flex-wrap: wrap;
                        }
                    }
                    input{
                        display: block;
                        width: 100%;
                        padding: 7px;
                        border-radius: var(--newsletter-input-radius);
                        border: none;
                        font-family: var(--font-primary);
                        background-color: var(--newsletter-input-bg);
                        color: var(--newsletter-input-text);
                        outline: none;

                        @include desktop{
                            min-width: 320px;
                        }

                        &::placeholder{
                            color: var(--newsletter-input-text);
                        }
                    }

                    button{
                        display: block;
                        width: 100%;
                        padding: 7px 30px;
                        border-radius: var(--newsletter-input-radius);
                        background-color: var(--newsletter-cta-bg);
                        color: var(--newsletter-cta-text);
                        border: none;
                        cursor: pointer;
                        @include mobile{
                            max-width: 120px;
                            margin: 0 auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        &[data-id="brands"]{
            
            margin-bottom: 40px;

            img{
                @include preload-image;
                height: 40px;
                object-fit: contain;
            }

            a{
                text-align: center;
                display: block;
            }
        }
        &[data-id="breadcrumb"]{
            ol{
                display: none;
            }
            .fbits-breadcrumb{
                display: none !important;
            }
            padding: 0 20px;
            ul{
                margin: 10px 0;
                list-style: none;
                display: flex;
                gap: 10px;
                align-items: center;

                li{
                    display: flex;
                    align-items: center;
                    &:not(:last-child)::after{
                        background-image: var(--icon-breadcrumb);
                        background-position: center center;
                        background-repeat: no-repeat;
                        content: "";
                        display: inline-block;
                        height: 15px;
                        width: 15px;
                        background-size: 52%;
                        margin-left: 10px;
                    }
                    list-style: none;

                    a{
                        color: #4F4F4F;
                    }
                }
            }
        }
        &[data-id="faq"]{
            >h2{
                font-weight: bold;
                text-align: center;
                color: #1D2F4F;
                margin-bottom: 10px;
            }

            div.faq__options{
                margin-bottom: 40px;

                > .faq__item:not(:last-child,.active) .faq__title{
                    border-bottom: none !important;
                }
                > .faq__item.active {
                    .faq__title{
                        background-color: transparent;
                        color: #1D2F4F;
                        svg{
                            transform: rotate(180deg);
                        }
                        border-bottom: none;
                    }
                    .faq__description{
                        display: block;
                        margin-bottom: 10px;
                        @include desktop{
                            padding: 10px 10px;
                        }
                        @include mobile{
                            padding: 0 5px;
                        }
                    }
                }
                .faq__item{
                   .faq__title{
                    border-top: 1px solid #21759A;
                    border-bottom: 1px solid #21759A;
                    padding: 10px 0;
                    font-weight: bold;
                    font-size: 16px;
                    transition: all .2s ease-out;
                    background-color: #F8F8F8;
                    @include desktop{
                        padding: 10px 10px;
                    }
                    cursor: pointer;
                    @include mobile{
                        font-size: 14px;
                        padding: 10px 5px;
                    }

                    svg{
                        margin-left: 10px;
                    }

                   }
                   .faq__description{
                    display: none;
                   }

                }
            }
        }
        &[data-id="custom-links"]{
            display: flex;
            justify-content: center;
            margin: 40px 0;
            @include mobile{
                flex-wrap: wrap;
                gap: 10px 0;
            }
            a{
                display: block;
                color: #FF5E00;
                text-decoration: none;
                @include mobile{
                    font-size: 12px;
                }
                &:not(:last-child)::after{
                    content: "|";
                    display: inline-block;
                    padding-left: 10px;
                    margin-right: 10px;
                    color: #1D2F4F;
                }
            }
        }
    }
}