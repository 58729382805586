.footer__area{
    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding-top: 20px;
    padding-bottom: 20px;

    svg,
    svg path{
        fill: var(--footer-icons);
    }

    .footer__logo{
        text-align: center;
    }

    .department__list{
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
        @include mobile{
            flex-direction: column;
        }

        h2,h4{
            color: var(--footer-title-text);
            font-size: 20px;
            margin-bottom: 15px;
            text-align: center;
            @include mobile{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 16px;
            }
        }

        div.department__item{
            flex: 0 1 25%;
            @include mobile{
                flex: 0 1 100%;
            }

            a{
                color: var(--footer-text);
            }

            p{
                text-align: center;
            }

            ul{
                list-style: none;
                text-align: center;

                li{
                    margin-bottom: 10px;
                }
            }
        }
    }

    .others_options{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        @include mobile{
            flex-direction: column;
        }

        .other__item{
            text-align: center;

            h2,h4{
                color: var(--footer-title-text);
                font-size: 20px;
                margin-bottom: 15px;
                text-align: center;
                @include mobile{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-size: 16px;
                }
            }

            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                li{
                    list-style: none;
                }
            }

            a.seal{
                margin-top: 0;
                display: block;
            }
        }
    }
}

.footer__copyright{
    margin-top: 20px;
    color: var(--footer-copyright-text);
    text-align: center;
    line-height: 1.5;
    font-size: 16px;

    @include desktop{
        svg{
            display: none;
        }
    }
    
    @include mobile{
        background-color: var(--footer-bg);
        padding-bottom: 20px;
        font-size: 12px;
        display: flex;
        flex-direction: column-reverse;
        svg,
        svg path{
            fill: var(--footer-icons);
        }
    }

    .footer__development{
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        margin-top: 10px;
        color: var(--footer-copyright-text);

        @include mobile{
            font-size: 16px;
            font-weight: bold;
        }

        @include mobile{
            flex-wrap: wrap;

                a{
                    display: block;
                    width: 100%;
                    margin-bottom: 20px;
                }
        }
    }
}

.whatsapp_float{
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #25D366;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 11;

    svg{
        fill: #fff;
        width: 25px;
        height: 25px;
    }
}

footer{
    margin: 0;
    padding: 0;
    font-size: 16px;
}